import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Sun, Moon, RefreshCw, Pause, Play, Maximize, Minimize, Info, Share2, X, Plus, Trash, Palette, Volume2, VolumeX, Share, User } from 'lucide-react';
import { Slider } from './components/ui/slider';
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga4';
import CookieBeleid from './pages/CookieBeleid';
import IntroScreen from './IntroScreen';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import UAParser from 'ua-parser-js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Constants
const MAX_CIRCLE_SIZE = Math.min(window.innerWidth * 0.70, window.innerHeight * 0.70);
const MIN_CIRCLE_SIZE = MAX_CIRCLE_SIZE * 0.4;
const STROKE_WIDTH = MAX_CIRCLE_SIZE * 0.04;

// Voeg deze constante toe voor de thema's
const THEMES = [
  { name: 'default', from: 'from-indigo-900', via: 'via-purple-900', to: 'to-pink-800' },
  { name: 'aurora', from: 'from-teal-800', via: 'via-green-700', to: 'to-cyan-600' },
  { name: 'twilight', from: 'from-blue-900', via: 'via-indigo-800', to: 'to-purple-700' },
  { name: 'cosmic', from: 'from-gray-900', via: 'via-purple-900', to: 'to-violet-800' },
  { name: 'ember', from: 'from-red-900', via: 'via-orange-800', to: 'to-amber-700' },
  { name: 'rose', from: 'from-pink-700', via: 'via-rose-600', to: 'to-pink-800' }, // Nieuw roze thema
];

const DEFAULT_FAVORITES = [
  { "name": "Standard | 5.5 per m", "settings": { "breathsPerRound": 30, "totalRounds": 3, "holdDuration": 60, "inhalationHoldDuration": 20, "exhaleDuration": 2, "inhaleDuration": 2, "variableBreathDuration": false, "progressiveHoldIncrease": true, "ademhalingenBreaths": 30, "ademhalingenInhale": 5.5, "ademhalingenInhaleHold": 0, "ademhalingenExhale": 5.5, "ademhalingenExhaleHold": 0 } },
  { "name": "Medium | 4-7-8", "settings": { "breathsPerRound": 40, "totalRounds": 4, "holdDuration": 60, "inhalationHoldDuration": 30, "exhaleDuration": 1, "inhaleDuration": 2, "variableBreathDuration": false, "progressiveHoldIncrease": true, "ademhalingenBreaths": 30, "ademhalingenInhale": 4, "ademhalingenInhaleHold": 7, "ademhalingenExhale": 8, "ademhalingenExhaleHold": 0 } },
  { "name": "DMT | Kriya", "settings": { "breathsPerRound": 80, "totalRounds": 2, "holdDuration": 60, "inhalationHoldDuration": 20, "exhaleDuration": 4, "inhaleDuration": 4, "variableBreathDuration": false, "progressiveHoldIncrease": false, "ademhalingenBreaths": 19, "ademhalingenInhale": 4, "ademhalingenInhaleHold": 4, "ademhalingenExhale": 6, "ademhalingenExhaleHold": 2 } },
  { "name": "Standard | Box 6s", "settings": { "breathsPerRound": 30, "totalRounds": 3, "holdDuration": 60, "inhalationHoldDuration": 20, "exhaleDuration": 2, "inhaleDuration": 2, "variableBreathDuration": false, "progressiveHoldIncrease": true, "ademhalingenBreaths": 30, "ademhalingenInhale": 6, "ademhalingenInhaleHold": 6, "ademhalingenExhale": 6, "ademhalingenExhaleHold": 6 } },
  { "name": "Variable | Coordination", "settings": { "breathsPerRound": 30, "totalRounds": 3, "holdDuration": 60, "inhalationHoldDuration": 20, "exhaleDuration": 3, "inhaleDuration": 4, "variableBreathDuration": true, "progressiveHoldIncrease": true, "ademhalingenBreaths": 20, "ademhalingenInhale": 2, "ademhalingenInhaleHold": 0, "ademhalingenExhale": 10, "ademhalingenExhaleHold": 0 } },
  { "name": "Variable M | Pranayama", "settings": { "breathsPerRound": 40, "totalRounds": 4, "holdDuration": 60, "inhalationHoldDuration": 30, "exhaleDuration": 1.5, "inhaleDuration": 3, "variableBreathDuration": false, "progressiveHoldIncrease": true, "ademhalingenBreaths": 30, "ademhalingenInhale": 5, "ademhalingenInhaleHold": 0, "ademhalingenExhale": 10, "ademhalingenExhaleHold": 0 } },
  { "name": "Progressive M | Box 4s", "settings": { "breathsPerRound": 40, "totalRounds": 3, "holdDuration": 60, "inhalationHoldDuration": 30, "exhaleDuration": 2, "inhaleDuration": 3, "variableBreathDuration": false, "progressiveHoldIncrease": true, "ademhalingenBreaths": 30, "ademhalingenInhale": 4, "ademhalingenInhaleHold": 4, "ademhalingenExhale": 4, "ademhalingenExhaleHold": 4 } },
  { "name": "Power | 4-7-8", "settings": { "breathsPerRound": 30, "totalRounds": 4, "holdDuration": 5, "inhalationHoldDuration": 60, "exhaleDuration": 1, "inhaleDuration": 3, "variableBreathDuration": true, "progressiveHoldIncrease": false, "ademhalingenBreaths": 10, "ademhalingenInhale": 4, "ademhalingenInhaleHold": 7, "ademhalingenExhale": 8, "ademhalingenExhaleHold": 0 } }
];

// Helper functions
const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);

  if (seconds >= 60) {
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  } else {
    return secs.toString();
  }
};

const getColor = (phase) => {
  if (phase === 'inhale') return '#22C55E';
  if (phase === 'exhale') return '#3B82F6';
  return '#F59E0B';
};

// Components
const BreathingCircle = ({ size, phase, holdTimer, maxHoldTime, sizes }) => (
  <svg width={sizes.maxSize} height={sizes.maxSize} viewBox={`-10 -10 ${sizes.maxSize + 20} ${sizes.maxSize + 20}`}>
    <circle
      cx={sizes.maxSize / 2}
      cy={sizes.maxSize / 2}
      r={(sizes.maxSize - sizes.strokeWidth) / 2}
      fill="none"
      stroke={getColor(phase)}
      strokeWidth={sizes.strokeWidth}
      strokeLinecap="round"
    />
    {(phase === 'inhale' || phase === 'exhale' || phase === 'postHoldInhale' || phase === 'postHoldExhale' || phase === 'inhaleHold' || phase === 'prepareHold') && (
      <circle
        cx={sizes.maxSize / 2}
        cy={sizes.maxSize / 2}
        r={phase === 'inhaleHold' || phase === 'prepareHold' ? (sizes.maxSize - sizes.strokeWidth) / 2 : (size - sizes.strokeWidth) / 2}
        fill={getColor(phase)}
        opacity="0.6"
      />
    )}
    {(phase === 'hold' || phase === 'inhaleHold' || phase === 'exhaleHold') && (
      <circle
        cx={sizes.maxSize / 2}
        cy={sizes.maxSize / 2}
        r={sizes.maxSize / 2 - sizes.strokeWidth / 2}
        fill="none"
        stroke="#F59E0B"
        strokeWidth={sizes.strokeWidth}
        strokeLinecap="round"
        strokeDasharray={Math.PI * (sizes.maxSize - sizes.strokeWidth)}
        strokeDashoffset={Math.PI * (sizes.maxSize - sizes.strokeWidth) * (1 - holdTimer / maxHoldTime)}
        transform={`rotate(-90 ${sizes.maxSize / 2} ${sizes.maxSize / 2})`}
      />
    )}
  </svg>
);

const BreathingApp = () => {
  console.log('BreathingApp rendering', new Date().toISOString());

  // State
  const [size, setSize] = useState(MIN_CIRCLE_SIZE);
  const [phase, setPhase] = useState('config');
  const [breathCount, setBreathCount] = useState(1);
  const [roundCount, setRoundCount] = useState(1);
  const [showConfig, setShowConfig] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [activeTab, setActiveTab] = useState('rondes');
  const [holdTimer, setHoldTimer] = useState(0);
  const [language, setLanguage] = useState('nl');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [currentPage, setCurrentPage] = useState('main');
  const [countdown, setCountdown] = useState(null);
  const [isSpecialPhase, setIsSpecialPhase] = useState(false);
  const [showIOSInstallPrompt, setShowIOSInstallPrompt] = useState(false);
  const [usePatterns, setUsePatterns] = useState(false);

  // Rondes settings
  const [breathsPerRound, setBreathsPerRound] = useState(30);
  const [totalRounds, setTotalRounds] = useState(3);
  const [holdDuration, setHoldDuration] = useState(60);
  const [inhalationHoldDuration, setInhalationHoldDuration] = useState(20);
  const [variableBreathDuration, setVariableBreathDuration] = useState(false);
  const [inhaleDuration, setInhaleDuration] = useState(2);
  const [exhaleDuration, setExhaleDuration] = useState(2); // Toegevoegd
  const [progressiveHoldIncrease, setProgressiveHoldIncrease] = useState(false);

  // Ademhalingen settings
  const [ademhalingenBreaths, setAdemhalingenBreaths] = useState(10);
  const [ademhalingenInhale, setAdemhalingenInhale] = useState(4);
  const [ademhalingenInhaleHold, setAdemhalingenInhaleHold] = useState(7);
  const [ademhalingenExhale, setAdemhalingenExhale] = useState(8);
  const [ademhalingenExhaleHold, setAdemhalingenExhaleHold] = useState(0);

  // Voeg deze state variabelen toe bij de andere state declaraties
  const [completedBreaths, setCompletedBreaths] = useState(0);
  const [isReadyToStart, setIsReadyToStart] = useState(false);
  const [vibrationEnabled, setVibrationEnabled] = useState(true);

  // Voeg deze nieuwe state toe bij de andere state declaraties
  const [breathPatterns, setBreathPatterns] = useState([
    { inhale: 2, exhale: 2, breaths: 10 }
  ]);

  // Voeg deze functie toe voor het beheren van patronen
  const addPattern = () => {
    setBreathPatterns([...breathPatterns, { inhale: 2, exhale: 2, breaths: 10 }]);
  };

  const updatePattern = (index, field, value) => {
    const newPatterns = [...breathPatterns];
    newPatterns[index] = { ...newPatterns[index], [field]: value };
    setBreathPatterns(newPatterns);
  };

  const removePattern = (index) => {
    if (breathPatterns.length > 1) {
      const newPatterns = breathPatterns.filter((_, i) => i !== index);
      setBreathPatterns(newPatterns);
    }
  };

  // Functie om te controleren of het een iOS-apparaat is
  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const animationRef = useRef(null);
  const prevTimestampRef = useRef(null);
  const progressRef = useRef(0);

  const [animationDuration, setAnimationDuration] = useState({
    inhale: 3000,
    inhaleHold: 2000,
    exhale: 3000,
    exhaleHold: 2000,
    postHoldInhale: 2000,
    postHoldExhale: 2000
  });

  const [sizes, setSizes] = useState({
    minSize: MIN_CIRCLE_SIZE,
    maxSize: MAX_CIRCLE_SIZE,
    strokeWidth: STROKE_WIDTH,
  });

  // Timer state
  const [remainingTime, setRemainingTime] = useState(0);
  const timerRef = useRef(null);
  const startTimeRef = useRef(null);

  // Translations
  const translations = {
    nl: {
      settings: "Instellingen",
      startExercise: "Start oefening",
      breathsPerRound: "Ademhalingen per ronde",
      totalRounds: "Aantal rondes",
      holdDuration: "Adem vasthouden",
      inhalationHoldDuration: "Ingeademde adem vasthouden",
      exhaleDuration: "Duur uitademing",
      inhaleDuration: "Duur inademing",
      variableBreathing: "Variabel inademen",
      progressiveHoldIncrease: "Langzaam ophogen",
      breaths: "Ademhalingen",
      inhalation: "Inademing",
      exhalation: "Uitademing",
      hold: "Adem vasthouden",
      breathing: "Ademhaling",
      round: "Ronde",
      resume: "Hervatten",
      pause: "Pauzeren",
      restart: "Herstarten",
      completed: "Oefening voltooid!",
      feelRefreshed: "Voel je verfrist en energiek!",
      startAgain: "Opnieuw beginnen",
      inhale: "Adem in...",
      exhale: "Adem uit...",
      holdInhale: "Houd inademing vast...",
      holdBreath: "Houd adem vast...",
      deepInhale: "Diep inademen...",
      slowExhale: "Langzaam uitademen...",
      rondes: "Rondes",
      ademhalingen: "Ademhalingen",
      favorites: "Favorieten",
      newFavoriteName: "Nieuwe favoriet naam",
      shareViaWhatsApp: "Deel favorieten",
      checkOutTheseSettings: "Bekijk deze ademhalingsinstellingen",
      settingsImported: "Nieuwe instellingen geïmporteerd!",
      settingsAdded: "Nieuwe instellingen toegevoegd aan favorieten!",
      copyLink: "Kopieer link",
      linkCopied: "Link gekopieerd naar klembord!",
      copyFailed: "Kopiëren mislukt. Selecteer en kopieer de link hieronder:",
      errorGeneratingLink: "Er is een fout opgetreden bij het genereren van de link.",
      settingsAdded: "Nieuwe instellingen toegevoegd aan favorieten!",
      noFavoritesYet: "Je hebt nog geen favorieten. Voeg er een toe!",
      login: "Inloggen",
      email: "E-mailadres",
      password: "Wachtwoord",
      invalidEmail: "Voer een geldig e-mailadres in",
      tempEmailNotAllowed: "Tijdelijke e-mailadressen zijn niet toegestaan",
      register: "Registreren",
      loginFailed: "Inloggen mislukt. Controleer je gegevens.",
      emailAlreadyExists: "Dit e-mailadres is al geregistreerd.",
      registrationFailed: "Registratie mislukt. Probeer het opnieuw.",
      captchaRequired: "Captcha vereist.",
      captchaFailed: "Captcha mislukt.",
      getReady: "Maak je klaar...",
      emailNoLongerValid: "Je e-mailadres is niet meer geldig. Log opnieuw in.",
      noInternetConnection: "Zorg eerst dat je een internetverbinding hebt. Je kunt nu niet inloggen.",
      loginFailed: "Inloggen mislukt. Controleer je gegevens.",
      passwordTooShort: "Wachtwoord moet minimaal 8 tekens lang zijn.",
      recaptchaServiceError: "Kon de reCAPTCHA-service niet bereiken. Probeer het later opnieuw of neem contact op met de ondersteuning.",
      networkError: "Er is een netwerkfout opgetreden. Controleer uw internetverbinding en probeer het opnieuw.",
      close: "Sluiten",
      load: "Laden",
      moveUp: "Omhoog verplaatsen",
      moveDown: "Omlaag verplaatsen",
      delete: "Verwijderen",
      addFavorite: "Favoriet toevoegen",
      exitFullScreen: "Volledig scherm verlaten",
      enterFullScreen: "Volledig scherm",
      changeTheme: "Thema wijzigen",
      disableSound: "Geluid uitschakelen",
      enableSound: "Geluid inschakelen",
      disableVibration: "Trilling uitschakelen",
      enableVibration: "Trilling inschakelen",
      addToHomeScreen: "Voeg toe aan startscherm",
      installInstructions: "Tik op de deel-knop, scroll naar beneden en tik op 'Zet op beginscherm'",
      gotIt: "Begrepen",
      breathingPatterns: "Ademhalingspatronen",
      breathsInPattern: "Ademhalingen in patroon",
      usePatterns: "Gebruik ademhalingspatronen",
    },
    en: {
      settings: "Settings",
      startExercise: "Start exercise",
      breathsPerRound: "Breaths per round",
      totalRounds: "Total rounds",
      holdDuration: "Hold duration",
      inhalationHoldDuration: "Inhalation hold duration",
      exhaleDuration: "Exhalation duration",
      inhaleDuration: "Inhalation duration",
      variableBreathing: "Variable breathing",
      progressiveHoldIncrease: "Progressive increase",
      breaths: "Breaths",
      inhalation: "Inhalation",
      exhalation: "Exhalation",
      hold: "Hold",
      breathing: "Breathing",
      round: "Round",
      resume: "Resume",
      pause: "Pause",
      restart: "Restart",
      completed: "Exercise completed!",
      feelRefreshed: "Feel refreshed and energized!",
      startAgain: "Start again",
      inhale: "Inhale...",
      exhale: "Exhale...",
      holdInhale: "Hold inhale...",
      holdBreath: "Hold breath...",
      deepInhale: "Deep inhale...",
      slowExhale: "Slow exhale...",
      rondes: "Rounds",
      ademhalingen: "Breaths",
      favorites: "Favorites",
      newFavoriteName: "New favorite name",
      shareViaWhatsApp: "Share favorites",
      checkOutTheseSettings: "Check out these breathing settings",
      settingsImported: "New settings imported!",
      settingsAdded: "New settings added to favorites!",
      copyLink: "Copy link",
      linkCopied: "Link copied to clipboard!",
      copyFailed: "Copy failed. Select and copy the link below:",
      errorGeneratingLink: "An error occurred while generating the link.",
      settingsAdded: "New settings added to favorites!",
      noFavoritesYet: "You don't have any favorites yet. Add one!",
      login: "Login",
      email: "Email address",
      password: "Password",
      invalidEmail: "Please enter a valid email address",
      tempEmailNotAllowed: "Temporary email addresses are not allowed",
      register: "Register",
      loginFailed: "Login failed. Please check your credentials.",
      emailAlreadyExists: "This email address is already registered.",
      registrationFailed: "Registration failed. Please try again.",
      captchaRequired: "Captcha required.",
      captchaFailed: "Captcha failed.",
      getReady: "Get ready...",
      emailNoLongerValid: "Your email is no longer valid. Please log in again.",
      noInternetConnection: "Please ensure you have an internet connection. You cannot log in at this time.",
      loginFailed: "Login failed. Please check your credentials.",
      passwordTooShort: "Password must be at least 8 characters long.",
      recaptchaServiceError: "Could not connect to the reCAPTCHA service. Please try again later or contact support.",
      networkError: "A network error occurred. Please check your internet connection and try again.",
      close: "Close",
      load: "Load",
      moveUp: "Move up",
      moveDown: "Move down",
      delete: "Delete",
      addFavorite: "Add favorite",
      exitFullScreen: "Exit full screen",
      enterFullScreen: "Enter full screen",
      changeTheme: "Change theme",
      disableSound: "Disable sound",
      enableSound: "Enable sound",
      disableVibration: "Disable vibration",
      enableVibration: "Enable vibration",
      addToHomeScreen: "Add to Home Screen",
      installInstructions: "Tap the share button, scroll down, and tap 'Add to Home Screen'",
      gotIt: "Got it",
      breathingPatterns: "Breathing Patterns",
      breathsInPattern: "Breaths in pattern",
      usePatterns: "Use breathing patterns",
    }
  };

  const t = (key) => translations[language][key];

  // Initialiseer de AudioContext en laad het geluidsbestand
  const initAudio = async () => {
    try {
      if (!audioContext.current) {
        audioContext.current = new (window.AudioContext || window.webkitAudioContext)();

        // Laad beide geluidsbestanden
        const [inhaleResponse, exhaleResponse] = await Promise.all([
          fetch(`${process.env.PUBLIC_URL}/sounds/inhale_sound.wav`),
          fetch(`${process.env.PUBLIC_URL}/sounds/exhale_sound.wav`)
        ]);

        const [inhaleArrayBuffer, exhaleArrayBuffer] = await Promise.all([
          inhaleResponse.arrayBuffer(),
          exhaleResponse.arrayBuffer()
        ]);

        const [inhaleBuffer, exhaleBuffer] = await Promise.all([
          audioContext.current.decodeAudioData(inhaleArrayBuffer),
          audioContext.current.decodeAudioData(exhaleArrayBuffer)
        ]);

        inhaleAudioBuffer.current = inhaleBuffer;
        exhaleAudioBuffer.current = exhaleBuffer;
        isAudioLoaded.current = true;
      }
      await audioContext.current.resume();
    } catch (error) {
      console.error('Error initializing audio:', error);
    }
  };

  // Voeg een functie toe om audio te initialiseren bij de eerste gebruikersinteractie
  const handleFirstInteraction = async () => {
    await initAudio();
    startExercise(); // Je originele startExercise functie
  };


  const [showFavoritesModal, setShowFavoritesModal] = useState(false);
  const [favorites, setFavorites] = useState(() => {
    const savedFavorites = localStorage.getItem('breathingAppFavorites');
    if (savedFavorites) {
      return JSON.parse(savedFavorites);
    }
    return DEFAULT_FAVORITES;
  });

  const [newFavoriteName, setNewFavoriteName] = useState('');

  const [showNotification, setShowNotification] = useState(false);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const scrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '10px',
      margin: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      borderRadius: '10px',
      border: '2px solid rgba(255, 255, 255, 0.1)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1)',
  };

  const deleteFavorite = (index) => {
    const updatedFavorites = favorites.filter((_, i) => i !== index);
    setFavorites(updatedFavorites);
    localStorage.setItem('breathingAppFavorites', JSON.stringify(updatedFavorites));
    ReactGA.event({
      category: 'Favorites',
      action: 'Deleted',
      label: favorites[index].name
    });
  };

  // Vervang de bestaande renderFavoritesModal functie door deze nieuwe versie
  const renderFavoritesModal = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className={`bg-gradient-to-br ${currentTheme.from} ${currentTheme.via} ${currentTheme.to} p-6 rounded-3xl shadow-2xl w-full max-w-sm mx-auto border border-white border-opacity-20 relative text-white`}
      >
        <h3 className="text-2xl font-bold mb-6 text-center tracking-wide">{t('favorites')}</h3>
        <button
          onClick={() => setShowFavoritesModal(false)}
          className="absolute top-4 right-4 text-white hover:text-pink-300 transition-colors duration-300"
          aria-label={t('close')}
        >
          <X size={24} />
        </button>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="favorites">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`space-y-2 max-h-60 overflow-y-auto pr-2 mb-4`}
                style={{
                  ...scrollbarStyles,
                  overflowY: 'auto',
                }}
              >
                <AnimatePresence mode="popLayout">
                  {favorites.length > 0 ? (
                    favorites.map((favorite, index) => (
                      <Draggable key={favorite.name} draggableId={favorite.name} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              cursor: snapshot.isDragging ? 'grabbing' : 'default',
                            }}
                          >
                            <motion.div
                              initial={{ opacity: 0, y: -20 }}
                              animate={{ 
                                opacity: 1, 
                                y: 0,
                                scale: snapshot.isDragging ? 1.02 : 1,
                                boxShadow: snapshot.isDragging 
                                  ? '0 8px 20px rgba(0,0,0,0.2)' 
                                  : '0 2px 4px rgba(0,0,0,0.1)',
                              }}
                              exit={{ opacity: 0, y: -20 }}
                              className={`flex items-center justify-between bg-white 
                                ${snapshot.isDragging ? 'bg-opacity-20 ring-2 ring-pink-500 ring-opacity-50' : 'bg-opacity-10'} 
                                rounded-full overflow-hidden hover:bg-opacity-20 transition-colors duration-200
                                ${snapshot.isDragging ? 'cursor-grabbing' : ''}`}
                            >
                              <div
                                {...provided.dragHandleProps}
                                className={`px-4 py-2 cursor-grab
                                  ${snapshot.isDragging ? 'text-pink-300 cursor-grabbing' : 'text-white'} 
                                  hover:text-pink-300 transition-colors duration-200`}
                              >
                                <div className="flex flex-col space-y-1">
                                  <div className="h-0.5 w-4 bg-current rounded-full"></div>
                                  <div className="h-0.5 w-4 bg-current rounded-full"></div>
                                  <div className="h-0.5 w-4 bg-current rounded-full"></div>
                                </div>
                              </div>
                              <button
                                onClick={() => loadFavorite(favorite)}
                                className="text-left flex-grow py-2 px-2 transition-colors duration-200"
                                aria-label={`${t('load')} ${favorite.name}`}
                              >
                                {favorite.name}
                              </button>
                              <button
                                onClick={() => deleteFavorite(index)}
                                className="p-2 text-red-400 hover:text-red-300 hover:bg-red-400 hover:bg-opacity-20 transition-colors duration-200"
                                aria-label={`${t('delete')} ${favorite.name}`}
                              >
                                <Trash size={20} />
                              </button>
                            </motion.div>
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <motion.p
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.2 }}
                      className="text-center text-gray-300 italic"
                    >
                      {t('noFavoritesYet')}
                    </motion.p>
                  )}
                  {provided.placeholder}
                </AnimatePresence>
                {snapshot.isDraggingOver && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="absolute inset-0 bg-white bg-opacity-5 rounded-2xl pointer-events-none"
                    style={{
                      zIndex: -1
                    }}
                  />
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="flex items-center mb-4">
          <input
            type="text"
            value={newFavoriteName}
            onChange={(e) => setNewFavoriteName(e.target.value)}
            placeholder={t('newFavoriteName')}
            className="flex-grow py-2 px-4 bg-white bg-opacity-10 rounded-full text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500 transition-all duration-200"
            aria-label={t('newFavoriteName')}
          />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={saveAsFavorite}
            className="ml-2 bg-white bg-opacity-20 hover:bg-opacity-30 text-white p-2 rounded-full transition-colors duration-200"
            aria-label={t('addFavorite')}
          >
            <Plus size={20} />
          </motion.button>
        </div>
        <div className="space-y-2">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={shareFavoritesViaWhatsApp}
            className="w-full py-2 bg-white bg-opacity-20 hover:bg-opacity-30 text-white rounded-full flex items-center justify-center transition-all duration-200 shadow-lg hover:shadow-xl text-sm font-semibold"
            aria-label={t('shareViaWhatsApp')}
          >
            <Share2 size={20} className="mr-2" />
            {t('shareViaWhatsApp')}
          </motion.button>
        </div>
      </motion.div>
    </motion.div>
  );

  // Voeg deze nieuwe functie toe voor het afhandelen van drag-and-drop
  const handleDragEnd = useCallback((result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    
    // Als er niet echt verplaatst is, doe dan niets
    if (source.index === destination.index) return;

    const newFavorites = Array.from(favorites);
    const [removed] = newFavorites.splice(source.index, 1);
    newFavorites.splice(destination.index, 0, removed);

    setFavorites(newFavorites);
    localStorage.setItem('breathingAppFavorites', JSON.stringify(newFavorites));

    ReactGA.event({
      category: 'Favorites',
      action: 'Reordered',
      label: `${source.index} to ${destination.index}`
    });
  }, [favorites]);

  // Timer functions
  const startTimer = useCallback(() => {
    if (!timerRef.current) {
      startTimeRef.current = Date.now();
      timerRef.current = requestAnimationFrame(updateTimer);
    }
  }, []);

  const pauseTimer = useCallback(() => {
    if (timerRef.current) {
      cancelAnimationFrame(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const resetTimer = useCallback((duration) => {
    setRemainingTime(duration);
    startTimeRef.current = null;
    if (timerRef.current) {
      cancelAnimationFrame(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const updateTimer = useCallback(() => {
    if (startTimeRef.current === null) {
      startTimeRef.current = Date.now();
    }

    const elapsed = (Date.now() - startTimeRef.current) / 1000;
    setRemainingTime((prevTime) => {
      const newTime = Math.max(0, prevTime - elapsed);
      if (newTime <= 0) {
        cancelAnimationFrame(timerRef.current);
        timerRef.current = null;
        return 0;
      }
      return newTime;
    });

    startTimeRef.current = Date.now();
    timerRef.current = requestAnimationFrame(updateTimer);
  }, []);

  const startNextRound = () => {
    setPhase('inhale');
    setSize(sizes.minSize);
    setBreathCount(1);
    setRoundCount(prev => prev + 1);
  };

  const continueAdemhalingen = () => {
    setPhase('inhale');
    setSize(sizes.minSize);
    setIsSpecialPhase(false);
  };

  const showLoginAfterRound = () => {
    setIsPaused(true);
    setShowLoginModal(true);
  };

  const showLoginAfterBreaths = () => {
    setIsPaused(true);
    setShowLoginModal(true);
  };

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
    resetBreathingStates();
    setShowConfig(true);
  };

  // Voeg deze nieuwe state variabele toe
  const [currentTheme, setCurrentTheme] = useState(() => {
    const savedTheme = localStorage.getItem('breathingAppTheme');
    return savedTheme ? JSON.parse(savedTheme) : THEMES[0];
  });

  // Voeg deze functie toe om het thema te wijzigen
  const changeTheme = useCallback(() => {
    setCurrentTheme(prevTheme => {
      const currentIndex = THEMES.findIndex(theme => theme.name === prevTheme.name);
      const nextIndex = (currentIndex + 1) % THEMES.length;
      const newTheme = THEMES[nextIndex];
      localStorage.setItem('breathingAppTheme', JSON.stringify(newTheme));
      return newTheme;
    });
  }, []);

  const resetBreathingStates = useCallback(() => {
    setPhase('config');
    setSize(MIN_CIRCLE_SIZE);
    setBreathCount(1);
    setRoundCount(1);
    setIsPaused(false);
    setHoldTimer(0);
    setIsSpecialPhase(false);
    setCompletedBreaths(0);
    setCurrentPage('main'); // Gebruik setCurrentPage in plaats van setCurrentPhaseIndex
    setCountdown(null);
    setIsReadyToStart(false);

    // Reset animation-related refs
    prevTimestampRef.current = null;
    progressRef.current = 0;
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
      animationRef.current = null;
    }

    // Reset timer-related states
    if (timerRef.current) {
      cancelAnimationFrame(timerRef.current);
      timerRef.current = null;
    }
    startTimeRef.current = null;
    setRemainingTime(0);
  }, []);

  // Voeg deze nieuwe state variabelen toe
  const [soundEnabled, setSoundEnabled] = useState(true);

  const audioContext = useRef(null);
  const inhaleAudioBuffer = useRef(null);
  const exhaleAudioBuffer = useRef(null);
  const isAudioLoaded = useRef(false);

  // Initialiseer de AudioContext en laad het geluidsbestand
  useEffect(() => {
    const initAudio = async () => {
      try {
        audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
        await audioContext.current.resume(); // Zorg ervoor dat de context actief is

        // Laad beide geluidsbestanden
        const [inhaleResponse, exhaleResponse] = await Promise.all([
          fetch(`${process.env.PUBLIC_URL}/sounds/inhale_sound.wav`),
          fetch(`${process.env.PUBLIC_URL}/sounds/exhale_sound.wav`)
        ]);

        const [inhaleArrayBuffer, exhaleArrayBuffer] = await Promise.all([
          inhaleResponse.arrayBuffer(),
          exhaleResponse.arrayBuffer()
        ]);

        const [inhaleBuffer, exhaleBuffer] = await Promise.all([
          audioContext.current.decodeAudioData(inhaleArrayBuffer),
          audioContext.current.decodeAudioData(exhaleArrayBuffer)
        ]);

        inhaleAudioBuffer.current = inhaleBuffer;
        exhaleAudioBuffer.current = exhaleBuffer;
        isAudioLoaded.current = true;
      } catch (error) {
        console.error('Error initializing audio:', error);
      }
    };

    initAudio();

    return () => {
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, []);


  // Pas de handlePhaseChange functie aan om het geluid iets eerder af te spelen
  const handlePhaseChange = useCallback(() => {
    if (phase === 'inhale' || phase === 'exhaleHold') {
      // Speel inhale geluid bij inademen en bij begin van uitgeademde adem inhouden
      if (soundEnabled && isAudioLoaded.current && audioContext.current) {
        const source = audioContext.current.createBufferSource();
        source.buffer = inhaleAudioBuffer.current;

        const gainNode = audioContext.current.createGain();
        gainNode.gain.setValueAtTime(0, audioContext.current.currentTime);
        gainNode.gain.linearRampToValueAtTime(1, audioContext.current.currentTime + 0.01);

        source.connect(gainNode);
        gainNode.connect(audioContext.current.destination);
        source.start(0);
      }
    } else if (phase === 'exhale' || phase === 'inhaleHold') {
      // Speel exhale geluid bij uitademen en bij begin van ingeademde adem inhouden
      if (soundEnabled && isAudioLoaded.current && audioContext.current) {
        const source = audioContext.current.createBufferSource();
        source.buffer = exhaleAudioBuffer.current;

        const gainNode = audioContext.current.createGain();
        gainNode.gain.setValueAtTime(0, audioContext.current.currentTime);
        gainNode.gain.linearRampToValueAtTime(1, audioContext.current.currentTime + 0.01);

        source.connect(gainNode);
        gainNode.connect(audioContext.current.destination);
        source.start(0);
      }
    }

    if ((phase === 'inhale' || phase === 'exhale' || phase === 'inhaleHold' || phase === 'exhaleHold') && vibrationEnabled && 'vibrate' in navigator) {
      navigator.vibrate(200);
    }
  }, [phase, soundEnabled, vibrationEnabled]);

  // Voeg deze useEffect toe om de handlePhaseChange functie aan te roepen wanneer de fase verandert
  useEffect(() => {
    handlePhaseChange();
  }, [phase, handlePhaseChange]);

  // Effects
  useEffect(() => {
    const loggedIn = localStorage.getItem('breathingAppLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, []);

  // useEffect om de prompt weer te geven bij de eerste keer
  useEffect(() => {
    const hasShownPrompt = localStorage.getItem('hasShownIOSInstallPrompt');
    if (isIOS() && !hasShownPrompt) {
      setShowIOSInstallPrompt(true);
      localStorage.setItem('hasShownIOSInstallPrompt', 'true'); // Onthoud dat de prompt is weergegeven
    }
  }, []);

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    setLanguage(userLanguage.startsWith('en') ? 'en' : 'nl');

    const hasSeenIntro = localStorage.getItem('hasSeenIntro');
    if (hasSeenIntro) {
      setShowIntro(false);
      setShowConfig(true);
    }

    // Laad favorieten uit cookies
    const loadFavorites = () => {
      const savedFavorites = localStorage.getItem('breathingAppFavorites');
      if (savedFavorites) {
        const parsedFavorites = JSON.parse(savedFavorites);
        console.log('Loaded favorites:', parsedFavorites);
        setFavorites(parsedFavorites);
      }
    };

    loadFavorites();
  }, []);

  useEffect(() => {
    const savedFavorites = localStorage.getItem('breathingAppFavorites');
    if (savedFavorites) {
      setFavorites(JSON.parse(savedFavorites));
    } else {
      localStorage.setItem('breathingAppFavorites', JSON.stringify(DEFAULT_FAVORITES));
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sharedSettings = urlParams.get('shared');

    if (sharedSettings) {
      console.log('Shared settings found in URL:', sharedSettings);
      processSharedSettings(sharedSettings);
      // Verwijder de query parameter uit de URL
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [favorites]);

  useEffect(() => {
    const handleResize = () => {
      setSizes({
        minSize: MIN_CIRCLE_SIZE,
        maxSize: Math.min(window.innerWidth * 0.70, window.innerHeight * 0.70),
        strokeWidth: Math.min(window.innerWidth * 0.70, window.innerHeight * 0.70) * 0.04,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isFullScreen]);

  useEffect(() => {
    if (phase !== 'config' && phase !== 'complete') {
      prevTimestampRef.current = null;
      progressRef.current = 0;
      animationRef.current = requestAnimationFrame(animate);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [phase, isPaused, breathCount, activeTab]);

  useEffect(() => {
    if (showConfig) {
      resetBreathingStates();
    }
  }, [showConfig, resetBreathingStates]);

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (timerRef.current) {
        cancelAnimationFrame(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const savedUsePatterns = localStorage.getItem('breathingAppUsePatterns');
    if (savedUsePatterns) {
      setUsePatterns(JSON.parse(savedUsePatterns));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('breathingAppUsePatterns', JSON.stringify(usePatterns));
  }, [usePatterns]);  

  useEffect(() => {
    if (phase !== 'config' && phase !== 'complete' && !isPaused) {
      startTimer();
    } else {
      pauseTimer();
    }
  }, [phase, isPaused, startTimer, pauseTimer]);

  useEffect(() => {
    if (executeRecaptcha) {
      console.log('reCAPTCHA is ready');
    } else {
      console.log('reCAPTCHA is not yet ready');
    }
  }, [executeRecaptcha]);

  // Settings changes
  useEffect(() => {
    ReactGA.event({
      category: 'Settings',
      action: 'Changed',
      label: 'Breaths Per Round',
      value: breathsPerRound
    });
  }, [breathsPerRound]);

  useEffect(() => {
    ReactGA.event({
      category: 'Settings',
      action: 'Changed',
      label: 'Total Rounds',
      value: totalRounds
    });
  }, [totalRounds]);

  // Language change
  useEffect(() => {
    ReactGA.event({
      category: 'Settings',
      action: 'Language Changed',
      label: language
    });
  }, [language]);

  // Functie om huidige instellingen op te slaan als favoriet
  const saveAsFavorite = () => {
    if (newFavoriteName.trim() === '') return;

    const newFavorite = {
      name: newFavoriteName,
      settings: {
        breathsPerRound,
        totalRounds,
        holdDuration,
        inhalationHoldDuration,
        inhaleDuration,
        exhaleDuration, // Toegevoegd
        variableBreathDuration,
        progressiveHoldIncrease,
        ademhalingenBreaths,
        ademhalingenInhale,
        ademhalingenInhaleHold,
        ademhalingenExhale,
        ademhalingenExhaleHold
      }
    };

    const updatedFavorites = [newFavorite, ...favorites];
    setFavorites(updatedFavorites);
    localStorage.setItem('breathingAppFavorites', JSON.stringify(updatedFavorites));
    setNewFavoriteName('');
    ReactGA.event({
      category: 'Favorites',
      action: 'Saved',
      label: newFavoriteName
    });
  };

  // Functie om een favoriet te laden
  const loadFavorite = (favorite) => {
    const { settings } = favorite;
    setBreathsPerRound(settings.breathsPerRound);
    setTotalRounds(settings.totalRounds);
    setHoldDuration(settings.holdDuration);
    setInhalationHoldDuration(settings.inhalationHoldDuration);
    setInhaleDuration(settings.inhaleDuration);
    setExhaleDuration(settings.exhaleDuration); // Toegevoegd
    setVariableBreathDuration(settings.variableBreathDuration);
    setProgressiveHoldIncrease(settings.progressiveHoldIncrease);
    setAdemhalingenBreaths(settings.ademhalingenBreaths);
    setAdemhalingenInhale(settings.ademhalingenInhale);
    setAdemhalingenInhaleHold(settings.ademhalingenInhaleHold);
    setAdemhalingenExhale(settings.ademhalingenExhale);
    setAdemhalingenExhaleHold(settings.ademhalingenExhaleHold);
    setShowFavoritesModal(false);
  };

  const generateShortUrl = async (longUrl) => {
    console.log('Probeer URL te verkorten:', longUrl);
    try {
      const response = await axios.get(`/api/shorten-url?url=${encodeURIComponent(longUrl)}`);
      console.log('Ontvangen response:', response);
      if (response.data && response.data.shortUrl) {
        console.log('Verkorte URL:', response.data.shortUrl);
        return response.data.shortUrl;
      } else {
        console.error('Onverwachte response structuur:', response.data);
        return longUrl;
      }
    } catch (error) {
      console.error('Fout bij het verkorten van URL:', error);
      return longUrl; // Gebruik de lange URL als fallback
    }
  };

  const shareFavoritesViaWhatsApp = async () => {
    console.log('Sharing started');
    const settingsToShare = favorites.reduce((acc, fav) => {
      acc[fav.name] = fav.settings;
      return acc;
    }, {});

    const encodedSettings = encodeSettings(settingsToShare);
    const shareUrl = `${window.location.origin}?shared=${encodedSettings}`;
    const urlToShare = await generateShortUrl(shareUrl);
    console.log('URL to share:', urlToShare);

    const textToShare = t('checkOutTheseSettings');

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Deel ademhalingsinstellingen',
          text: textToShare,
          url: urlToShare,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      // Fallback voor niet-mobiele apparaten of voor apparaten zonder native sharing
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(textToShare + ': ' + urlToShare)}`;
      window.open(whatsappUrl, '_blank');
    }
    ReactGA.event({
      category: 'Share',
      action: 'WhatsApp',
      label: 'Favorites'
    });
  };

  const encodeSettings = (settings) => {
    return btoa(JSON.stringify(settings));
  };

  const decodeSettings = (encodedSettings) => {
    return JSON.parse(atob(encodedSettings));
  };

  const processSharedSettings = (sharedSettings) => {
    console.log('Processing shared settings:', sharedSettings);
    const decodedSettings = decodeSettings(sharedSettings);
    console.log('Decoded settings:', decodedSettings);

    // Haal de huidige favorieten direct uit localStorage
    const savedFavorites = localStorage.getItem('breathingAppFavorites');
    let currentFavorites = savedFavorites ? JSON.parse(savedFavorites) : [];
    console.log('Current favorites:', currentFavorites);

    // Maak een nieuwe array voor de bijgewerkte favorieten
    const updatedFavorites = [...currentFavorites];

    // Voeg de gedeelde instellingen toe aan de bestaande favorieten
    for (const [name, settings] of Object.entries(decodedSettings)) {
      let newName = name;
      let counter = 1;
      while (updatedFavorites.some(fav => fav.name === newName)) {
        newName = `${name} (${counter})`;
        counter++;
      }

      // Voeg altijd toe, omdat we zeker weten dat de naam uniek is
      updatedFavorites.push({ name: newName, settings });
    }

    console.log('Updated favorites:', updatedFavorites);

    // Update altijd, omdat we nieuwe items hebben toegevoegd
    setFavorites(updatedFavorites);
    localStorage.setItem('breathingAppFavorites', JSON.stringify(updatedFavorites));
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };

  // Helper functions
  const generateVariableDuration = () => {
    const minDuration = 1000;
    const maxDuration = inhaleDuration * 1000;
    const randomInhaleDuration = Math.random() * (maxDuration - minDuration) + minDuration;
    return {
      inhale: randomInhaleDuration,
      exhale: inhaleDuration * 1000, // Changed exhaleDuration to inhaleDuration
      hold: holdDuration * 1000,
      inhaleHold: inhalationHoldDuration * 1000,
      postHoldInhale: 2000,
      postHoldExhale: 2000
    };
  };


  const startExercise = () => {
    setCountdown(3);
    setIsReadyToStart(false);
    setShowConfig(false);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(countdownInterval);
          initializeExercise();
          return null;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const initializeExercise = () => {
    setPhase('inhale');
    setBreathCount(1);
    setRoundCount(1);
    setIsPaused(false);
    setCompletedBreaths(0);
    setCurrentPage('main'); // Gebruik setCurrentPage in plaats van setCurrentPhaseIndex

    if (activeTab === 'rondes') {
      startRondesExercise();
    } else {
      startAdemhalingenExercise();
    }

    resetTimer(calculateTotalDuration());
    startTimer();

    prevTimestampRef.current = null;
    progressRef.current = 0;

    animationRef.current = requestAnimationFrame(animate);

    ReactGA.event({
      category: 'Exercise',
      action: 'Started',
      label: activeTab
    });

    setIsReadyToStart(true);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');

    if (!validateEmail(email, setLoginError, t)) {
      ReactGA.event({
        category: 'User',
        action: 'Login Attempt',
        label: 'Invalid Email'
      });
      return;
    }

    try {
      const response = await axios.post('/api/login', { email, password });
      if (response.data.message === 'Login successful') {
        ReactGA.event({
          category: 'User',
          action: 'Login',
          label: 'Success'
        });
        localStorage.setItem('breathingAppEmail', email);
        localStorage.setItem('breathingAppLoggedIn', 'true');
        setIsLoggedIn(true);
        setShowLoginModal(false);
        setIsPaused(false);

        if (activeTab === 'rondes') {
          startNextRound();
        } else {
          continueAdemhalingen();
        }
      }
    } catch (error) {
      ReactGA.event({
        category: 'User',
        action: 'Login',
        label: 'Failed'
      });
      setLoginError(t('loginFailed'));
    }
  };

  const renderLoginModal = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg text-white p-4 rounded-3xl shadow-2xl w-full max-w-[340px] mx-auto border border-white border-opacity-20 relative"
      >
        <h3 className="text-2xl font-bold mb-6 text-center tracking-wide">{t('login')}</h3>
        <button
          onClick={handleCloseLoginModal}
          className="absolute top-4 right-4 text-white hover:text-pink-300 transition-colors duration-300"
          aria-label={t('close')}
        >
          <X size={24} />
        </button>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium mb-1">{t('email')}</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 bg-white bg-opacity-10 rounded-full text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500 transition-all duration-200"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium mb-1">{t('password')}</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 bg-white bg-opacity-10 rounded-full text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500 transition-all duration-200"
              required
            />
          </div>
          {loginError && <p className="text-red-400 text-sm">{loginError}</p>}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            className="w-full py-2 bg-gradient-to-r from-pink-500 to-purple-600 text-white rounded-full text-sm font-semibold hover:from-pink-600 hover:to-purple-700 transition-colors transform duration-200 shadow-lg"
          >
            {t('login')}
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleRegister}
            className="w-full py-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-full text-sm font-semibold hover:from-blue-600 hover:to-indigo-700 transition-colors transform duration-200 shadow-lg"
          >
            {t('register')}
          </motion.button>
        </form>
      </motion.div>
    </motion.div>
  );

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    console.log('About to execute reCAPTCHA...');
    const maxRetries = 3;
    for (let i = 0; i < maxRetries; i++) {
      try {
        const token = await executeRecaptcha('register');
        console.log('reCAPTCHA executed successfully, token length:', token.length);
        return token;
      } catch (error) {
        console.error(`Error executing reCAPTCHA (attempt ${i + 1}/${maxRetries}):`, error);
        if (i === maxRetries - 1) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  }, [executeRecaptcha]);

  const validateEmail = (email, setLoginError, t) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setLoginError(t('invalidEmail'));
      return false;
    }

    const tempMailDomains = ['tempmail.com', 'temp-mail.org', 'guerrillamail.com', '10minutemail.com', 'mailinator.com', 'throwawaymail.com', 'yopmail.com', 'tempmail.ninja', 'fakeinbox.com', 'tempail.com', 'emailondeck.com', 'dispostable.com', 'mailnesia.com', 'tempmailaddress.com', 'mytemp.email', 'burnermail.io', 'tempmailer.com', 'fowarding.email', 'spamgourmet.com', 'trashmail.com', 'getnada.com', 'tempr.email', 'temp-mail.io', '33mail.com', 'hidemail.de', 'maildrop.cc', 'mailcatch.com', 'tempmailgen.com', 'tempmail.plus', 'tempmail.net', 'sharklasers.com', 'armyspy.com', 'cuvox.de', 'dayrep.com', 'einrot.com', 'fleckens.hu', 'gustr.com', 'jourrapide.com', 'rhyta.com', 'superrito.com', 'teleworm.us', 'disposableaddress.com', 'mytrashmail.com', 'filzmail.com', 'fakemail.net', 'mailforspam.com', 'meltmail.com', 'anonymbox.net', 'generator.email', 'anonbox.net', 'tempmailbox.com'];
    const emailDomain = email.split('@')[1].toLowerCase();
    if (tempMailDomains.includes(emailDomain)) {
      setLoginError(t('tempEmailNotAllowed'));
      return false;
    }

    return true;
  };

  const validatePassword = (password, setLoginError, t) => {
    if (password.length < 8) {
      setLoginError(t('passwordTooShort'));
      return false;
    }
    return true;
  };

  const handleRegister = useCallback(async (e) => {
    e.preventDefault();
    setLoginError('');

    if (!validateEmail(email, setLoginError, t)) {
      ReactGA.event({
        category: 'User',
        action: 'Registration Attempt',
        label: 'Invalid Email'
      });
      return;
    }

    if (!validatePassword(password, setLoginError, t)) {
      ReactGA.event({
        category: 'User',
        action: 'Registration Attempt',
        label: 'Invalid Password'
      });
      return;
    }

    try {
      let captchaToken = null;
      if (executeRecaptcha) {
        console.log('Attempting to execute recaptcha');
        captchaToken = await handleReCaptchaVerify();
        console.log('reCAPTCHA executed, token length:', captchaToken ? captchaToken.length : 'no token');

        if (!captchaToken) {
          console.log('reCAPTCHA token is empty or undefined');
          setLoginError(t('recaptchaFailed'));
          return;
        }
      } else {
        console.warn('reCAPTCHA not available, proceeding without verification');
      }

      const parser = new UAParser();
      const userAgent = parser.getResult();
      const deviceInfo = {
        browser: userAgent.browser.name,
        os: userAgent.os.name,
        device: userAgent.device.type || 'desktop',
      };

      console.log('Fetching IP address');
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const ipAddress = ipResponse.data.ip;
      console.log('IP address:', ipAddress);

      console.log('Fetching geolocation data');
      const geoResponse = await axios.get(`https://ipapi.co/${ipAddress}/json/`);
      const location = {
        country: geoResponse.data.country_name,
        city: geoResponse.data.city,
        region: geoResponse.data.region,
      };
      console.log('Geolocation data:', location);

      const registrationData = {
        email,
        password,
        captchaToken,
        deviceInfo,
        ipAddress,
        location,
        timestamp: new Date().toISOString()
      };
      console.log('Sending registration data:', { ...registrationData, password: '******' });

      const response = await axios.post('/api/register', registrationData);

      console.log('Registration response:', response.data);
      if (response.data.message === 'User registered successfully') {
        ReactGA.event({
          category: 'User',
          action: 'Registration',
          label: 'Success'
        });
        localStorage.setItem('breathingAppEmail', email);
        localStorage.setItem('breathingAppLoggedIn', 'true');
        setIsLoggedIn(true);
        setShowLoginModal(false);
        setIsPaused(false);

        if (activeTab === 'rondes') {
          startNextRound();
        } else {
          continueAdemhalingen();
        }
      }
    } catch (error) {
      console.error('Registration error:', error);
      ReactGA.event({
        category: 'User',
        action: 'Registration',
        label: 'Failed'
      });
      if (error.message.includes('reCAPTCHA service')) {
        setLoginError(t('recaptchaServiceError'));
      } else if (error.message.includes('network error')) {
        setLoginError(t('networkError'));
      } else {
        handleRegistrationError(error, setLoginError, t);
      }
    }
  }, [email, password, t, handleReCaptchaVerify, executeRecaptcha, activeTab]);

  const handleRegistrationError = (error, setLoginError, t) => {
    if (error.response) {
      console.log('Error response:', error.response);
      if (error.response.data && error.response.data.error) {
        console.log('Specific error:', error.response.data.error);
        if (error.response.data.error === 'emailAlreadyExists') {
          setLoginError(t('emailAlreadyExists'));
        } else if (error.response.data.error === 'captchaFailed') {
          setLoginError(t('captchaFailed'));
        } else {
          setLoginError(t(error.response.data.error));
        }
      } else {
        setLoginError(t('registrationFailed'));
      }
    } else if (error.request) {
      console.log('Error request:', error.request);
      setLoginError(t('networkError'));
    } else {
      console.log('Error message:', error.message);
      setLoginError(t('unknownError'));
    }
  };

  const startRondesExercise = () => {
    if (variableBreathDuration) {
      setAnimationDuration(generateVariableDuration());
    } else {
      const currentPattern = getCurrentPattern();
      setAnimationDuration({
        inhale: currentPattern.inhale * 1000,
        exhale: currentPattern.exhale * 1000,
        hold: holdDuration * 1000,
        inhaleHold: inhalationHoldDuration * 1000,
        postHoldInhale: 2000,
        postHoldExhale: 2000
      });
    }
  };

  const startAdemhalingenExercise = () => {
    setAnimationDuration({
      inhale: ademhalingenInhale * 1000,
      inhaleHold: ademhalingenInhaleHold * 1000,
      exhale: ademhalingenExhale * 1000,
      exhaleHold: ademhalingenExhaleHold * 1000,
      postHoldInhale: 2000,
      postHoldExhale: 2000
    });
  };

  const animate = useCallback((timestamp) => {
    if (isPaused || phase === 'complete') return;

    if (prevTimestampRef.current === null) {
      prevTimestampRef.current = timestamp;
    }

    const elapsed = timestamp - prevTimestampRef.current;
    prevTimestampRef.current = timestamp;

    if (isSpecialPhase) {
      setHoldTimer(prevHoldTimer => {
        const newHoldTimer = prevHoldTimer - elapsed / 1000;
        if (newHoldTimer <= 0) {
          if (activeTab === 'rondes') {
            handleRondesPhaseChange();
          } else {
            handleAdemhalingenPhaseChange();
          }
          return 0;
        }
        return newHoldTimer;
      });
    } else {
      const duration = animationDuration[phase];
      progressRef.current += elapsed / duration;

      if (progressRef.current >= 1) {
        console.log('Phase about to change'); // Debug log
        progressRef.current = 0;
        if (activeTab === 'rondes') {
          handleRondesPhaseChange();
        } else {
          handleAdemhalingenPhaseChange();
        }
      } else {
        updateCircleSize();
      }
    }

    if (phase !== 'complete') {
      animationRef.current = requestAnimationFrame(animate);
    }
  }, [isPaused, phase, isSpecialPhase, activeTab, animationDuration]);

  const handleRondesPhaseChange = () => {
    console.log('Phase set to:', phase);
    switch (phase) {
      case 'inhale':
        setPhase('exhale');
        setSize(sizes.maxSize);
        break;
      case 'exhale':
        if (usePatterns) {
          // Bereken het totaal aantal ademhalingen voor alle patronen
          const totalPatternBreaths = breathPatterns.reduce((sum, pattern) => sum + pattern.breaths, 0);
          
          if (breathCount < totalPatternBreaths) {
            setPhase('inhale');
            setSize(sizes.minSize);
            setBreathCount(prev => {
              const newCount = prev + 1;
              // Update het patroon wanneer we naar de volgende ademhaling gaan
              const currentPattern = getCurrentPattern();
              setAnimationDuration(prev => ({
                ...prev,
                inhale: currentPattern.inhale * 1000,
                exhale: currentPattern.exhale * 1000
              }));
              return newCount;
            });
          } else {
            setPhase('prepareHold');
            setSize(sizes.maxSize);
            setIsSpecialPhase(true);
            setTimeout(() => {
              setPhase('hold');
              const currentHoldDuration = progressiveHoldIncrease
                ? holdDuration + (roundCount - 1) * 30
                : holdDuration;
              setHoldTimer(currentHoldDuration);
              setAnimationDuration(prev => ({ ...prev, hold: currentHoldDuration * 1000 }));
            }, 2000);
          }
        } else {
          // Bestaande logica voor niet-patroon modus blijft hetzelfde
          if (breathCount < breathsPerRound) {
            setPhase('inhale');
            setSize(sizes.minSize);
            setBreathCount(prev => prev + 1);
            if (variableBreathDuration) {
              setAnimationDuration(generateVariableDuration());
            }
          } else {
            setPhase('prepareHold');
            setSize(sizes.maxSize);
            setIsSpecialPhase(true);
            setTimeout(() => {
              setPhase('hold');
              const currentHoldDuration = progressiveHoldIncrease
                ? holdDuration + (roundCount - 1) * 30
                : holdDuration;
              setHoldTimer(currentHoldDuration);
              setAnimationDuration(prev => ({ ...prev, hold: currentHoldDuration * 1000 }));
            }, 2000);
          }
        }
        break;
      case 'hold':
        setPhase('postHoldInhale');
        setSize(sizes.minSize);
        setIsSpecialPhase(false);
        break;
      case 'postHoldInhale':
        if (inhalationHoldDuration > 0) {
          setPhase('inhaleHold');
          setHoldTimer(inhalationHoldDuration);
          setIsSpecialPhase(true);
        } else {
          setPhase('postHoldExhale');
          setSize(sizes.maxSize);
        }
        break;
      case 'inhaleHold':
        setPhase('postHoldExhale');
        setSize(sizes.maxSize);
        setIsSpecialPhase(false);
        break;
      case 'postHoldExhale':
        if (roundCount < totalRounds) {
          if (!isLoggedIn && roundCount === 1) {
            showLoginAfterRound();
          } else {
            startNextRound();
          }
        } else {
          setPhase('complete');
        }
        break;
      default:
        break;
    }
  };

  const handleAdemhalingenPhaseChange = () => {
    console.log('Phase set to:', phase); // Debug log
    if (breathCount >= ademhalingenBreaths && remainingTime <= 0) {
      setPhase('complete');
      return;
    }

    switch (phase) {
      case 'inhale':
        if (ademhalingenInhaleHold > 0) {
          setPhase('inhaleHold');
          setSize(sizes.maxSize);
          setHoldTimer(ademhalingenInhaleHold);
          setIsSpecialPhase(true);
        } else {
          setPhase('exhale');
          setSize(sizes.minSize);
        }
        break;
      case 'inhaleHold':
        setPhase('exhale');
        setSize(sizes.minSize);
        setIsSpecialPhase(false);
        break;
      case 'exhale':
        if (ademhalingenExhaleHold > 0) {
          setPhase('exhaleHold');
          setSize(sizes.minSize);
          setHoldTimer(ademhalingenExhaleHold);
          setIsSpecialPhase(true);
        } else {
          setPhase('inhale');
          setSize(sizes.minSize);
          updateBreathCount();
        }
        break;
      case 'exhaleHold':
        if (!isLoggedIn && breathCount === Math.floor(ademhalingenBreaths / 2)) {
          showLoginAfterBreaths();
        } else {
          setPhase('inhale');
          setSize(sizes.minSize);
          updateBreathCount();
          setIsSpecialPhase(false);
        }
        break;
      default:
        break;
    }
  };

  const updateBreathCount = () => {
    setBreathCount(prev => {
      const newCount = prev + 1;
      if (newCount > ademhalingenBreaths) {
        setPhase('complete');
        return ademhalingenBreaths;
      } else {
        if (!isLoggedIn && newCount === Math.floor(ademhalingenBreaths / 2)) {
          showLoginAfterBreaths();
        } else {
          setPhase('inhale');
          setSize(sizes.minSize);
          setIsSpecialPhase(false);
        }
        return newCount;
      }
    });
  };

  const updateCircleSize = () => {
    if (phase !== 'inhaleHold' && phase !== 'prepareHold') {
      const newSize = (phase === 'inhale' || phase === 'postHoldInhale')
        ? sizes.minSize + (sizes.maxSize - sizes.minSize) * progressRef.current
        : sizes.maxSize - (sizes.maxSize - sizes.minSize) * progressRef.current;
      setSize(newSize);
    }
  };

  const calculateTotalDuration = () => {
    if (activeTab === 'rondes') {
      if (variableBreathDuration) {
        const maxBreathDuration = inhaleDuration + exhaleDuration;
        const roundDuration =
          (breathsPerRound * maxBreathDuration) +
          holdDuration +
          inhalationHoldDuration +
          4;
        return Math.ceil(totalRounds * roundDuration +
          (progressiveHoldIncrease ? (30 * totalRounds * (totalRounds - 1) / 2) : 0));
      } else {
        const roundDuration =
          (breathsPerRound * inhaleDuration) +
          (breathsPerRound * exhaleDuration) +
          holdDuration +
          inhalationHoldDuration +
          4;
        return Math.ceil(totalRounds * roundDuration +
          (progressiveHoldIncrease ? (30 * totalRounds * (totalRounds - 1) / 2) : 0));
      }
    } else if (activeTab === 'ademhalingen') {
      return Math.ceil(ademhalingenBreaths * (
        Math.max(ademhalingenInhale, 0) +
        Math.max(ademhalingenInhaleHold, 0) +
        Math.max(ademhalingenExhale, 0) +
        Math.max(ademhalingenExhaleHold, 0)
      ));
    }
    return 0;
  };

  const restartExercise = () => {
    resetBreathingStates();
    setShowConfig(true);
    setIsFullScreen(false);
  };

  const togglePause = () => {
    setIsPaused(prev => {
      if (prev) {
        startTimer();
      } else {
        pauseTimer();
      }
      return !prev;
    });
  };

  const toggleFullScreen = () => {
    setIsFullScreen(prev => !prev);
  };

  const getInstructionText = () => {
    switch (phase) {
      case 'inhale':
        return t('inhale');
      case 'exhale':
        return t('exhale');
      case 'inhaleHold':
        return t('holdInhale');
      case 'exhaleHold':
      case 'hold':
      case 'prepareHold':
        return t('holdBreath');
      case 'postHoldInhale':
        return t('deepInhale');
      case 'postHoldExhale':
        return t('slowExhale');
      default:
        return '';
    }
  };

  const handleCloseIntro = () => {
    setShowIntro(false);
    localStorage.setItem('hasSeenIntro', 'true');
    setShowConfig(true);
  };

  const renderSliderWithTicks = (value, setValue, min, max, step, label, unit, formatValue = (v) => v) => {
    return (
      <div className="mb-4">
        <div className="flex justify-between items-center mb-1">
          <label className="text-sm font-medium text-white">{label}</label>
          <span className="text-sm font-bold text-white">{formatValue(value)} {unit}</span>
        </div>
        <div className="relative pt-1">
          <Slider
            value={[value]}
            onValueChange={(newValue) => setValue(newValue[0])}
            max={max}
            min={min}
            step={step}
            className="relative z-10"
            thumbClassName="w-5 h-5 bg-white"
            trackClassName="bg-white bg-opacity-50"
          />
        </div>
      </div>
    );
  };

  const renderRondesSettings = () => (
    <div className="space-y-4">
      {!usePatterns ? (
        // Toon de normale instellingen
        <>
          {renderSliderWithTicks(breathsPerRound, setBreathsPerRound, 5, 100, 5, t('breathsPerRound'), "")}
          {renderSliderWithTicks(totalRounds, setTotalRounds, 1, 10, 1, t('totalRounds'), "")}
          {renderSliderWithTicks(holdDuration, setHoldDuration, 5, 180, 5, t('holdDuration'), "sec")}
          {renderSliderWithTicks(inhalationHoldDuration, setInhalationHoldDuration, 5, 60, 5, t('inhalationHoldDuration'), "sec")}
          {renderSliderWithTicks(inhaleDuration, setInhaleDuration, 0.1, 10, 0.1, t('inhaleDuration'), "sec")}
          {renderSliderWithTicks(exhaleDuration, setExhaleDuration, 0.1, 10, 0.1, t('exhaleDuration'), "sec")}
          <div className="flex justify-between space-x-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={variableBreathDuration}
                onChange={(e) => setVariableBreathDuration(e.target.checked)}
                className="form-checkbox h-4 w-4 text-pink-500 rounded"
              />
              <span className="ml-2 text-xs text-white font-medium">{t('variableBreathing')}</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={progressiveHoldIncrease}
                onChange={(e) => setProgressiveHoldIncrease(e.target.checked)}
                className="form-checkbox h-4 w-4 text-pink-500 rounded"
              />
              <span className="ml-2 text-xs text-white font-medium">{t('progressiveHoldIncrease')}</span>
            </label>
          </div>
          {/* Toggle switch naar beneden verplaatst */}
          <div className="flex items-center justify-between mt-6 bg-white bg-opacity-10 p-3 rounded-xl">
            <span className="text-sm font-medium">{t('usePatterns')}</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={usePatterns}
                onChange={(e) => setUsePatterns(e.target.checked)}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-pink-500"></div>
            </label>
          </div>
        </>
      ) : (
        // Toon alleen de patronen instellingen met ronde configuratie
        <>
          {renderSliderWithTicks(totalRounds, setTotalRounds, 1, 10, 1, t('totalRounds'), "")}
          {renderSliderWithTicks(holdDuration, setHoldDuration, 5, 180, 5, t('holdDuration'), "sec")}
          {renderSliderWithTicks(inhalationHoldDuration, setInhalationHoldDuration, 5, 60, 5, t('inhalationHoldDuration'), "sec")}
          <div className="flex justify-between space-x-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={progressiveHoldIncrease}
                onChange={(e) => setProgressiveHoldIncrease(e.target.checked)}
                className="form-checkbox h-4 w-4 text-pink-500 rounded"
              />
              <span className="ml-2 text-xs text-white font-medium">{t('progressiveHoldIncrease')}</span>
            </label>
          </div>
          {renderPatternSettings()}
          {/* Toggle switch voor patronen modus */}
          <div className="flex items-center justify-between mt-6 bg-white bg-opacity-10 p-3 rounded-xl">
            <span className="text-sm font-medium">{t('usePatterns')}</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={usePatterns}
                onChange={(e) => setUsePatterns(e.target.checked)}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-pink-500"></div>
            </label>
          </div>
        </>
      )}
    </div>
  );

  const renderPatternSettings = () => (
    <div className="space-y-4 mt-4 border-t border-white border-opacity-20 pt-4">
      <div className="flex justify-between items-center">
        <h3 className="text-sm font-medium text-white">{t('breathingPatterns')}</h3>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={addPattern}
          className="p-1 bg-white bg-opacity-20 rounded-full hover:bg-opacity-30 transition-colors duration-200"
        >
          <Plus size={16} />
        </motion.button>
      </div>
      {breathPatterns.map((pattern, index) => (
        <div key={index} className="bg-white bg-opacity-10 rounded-xl p-3 space-y-2">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm font-medium">Patroon {index + 1}</span>
            {breathPatterns.length > 1 && (
              <button
                onClick={() => removePattern(index)}
                className="text-red-400 hover:text-red-300"
              >
                <Trash size={16} />
              </button>
            )}
          </div>
          {renderSliderWithTicks(
            pattern.inhale,
            (value) => updatePattern(index, 'inhale', value),
            0.1,
            10,
            0.1,
            t('inhaleDuration'),
            "sec"
          )}
          {renderSliderWithTicks(
            pattern.exhale,
            (value) => updatePattern(index, 'exhale', value),
            0.1,
            10,
            0.1,
            t('exhaleDuration'),
            "sec"
          )}
          {renderSliderWithTicks(
            pattern.breaths,
            (value) => updatePattern(index, 'breaths', value),
            1,
            30,
            1,
            t('breathsInPattern'),
            ""
          )}
        </div>
      ))}
    </div>
  );

  const renderAdemhalingenSettings = () => (
    <div className="space-y-4">
      {renderSliderWithTicks(ademhalingenBreaths, setAdemhalingenBreaths, 1, 100, 1, t('breaths'), "")}
      {renderSliderWithTicks(ademhalingenInhale, setAdemhalingenInhale, 0, 20, 0.5, t('inhalation'), "sec")}
      {renderSliderWithTicks(ademhalingenInhaleHold, setAdemhalingenInhaleHold, 0, 20, 0.5, t('inhalationHoldDuration'), "sec")}
      {renderSliderWithTicks(ademhalingenExhale, setAdemhalingenExhale, 0, 20, 0.5, t('exhalation'), "sec")}
      {renderSliderWithTicks(ademhalingenExhaleHold, setAdemhalingenExhaleHold, 0, 20, 0.5, t('holdDuration'), "sec")}
      <div className="h-[45px]"></div>
    </div>
  );

  const renderMainContent = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={`flex flex-col items-center justify-center min-h-screen bg-gradient-to-br ${currentTheme.from} ${currentTheme.via} ${currentTheme.to} ${isFullScreen ? 'fixed inset-0 z-50' : ''}`}
    >
      {countdown !== null ? (
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="flex flex-col items-center justify-center h-screen"
        >
          <motion.div
            key={countdown}
            initial={{ scale: 1.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="text-white text-9xl font-bold"
          >
            {countdown === 0 ? t('getReady') : countdown}
          </motion.div>
        </motion.div>
      ) : showConfig ? (
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg text-white p-4 rounded-3xl shadow-2xl w-full max-w-[340px] mx-auto border border-white border-opacity-20 relative"
        >
          <h2 className="text-xl font-bold mb-4 text-center tracking-wide">{t('settings')}</h2>
          <div className="absolute top-2 left-2 flex space-x-2">
            <button
              onClick={() => setShowFavoritesModal(true)}
              className="p-2 text-white hover:text-pink-300 transition-colors duration-300"
              title={t('favorites')}
            >
              <User size={24} />
            </button>
          </div>
          <button
            onClick={() => setShowIntro(true)}
            className="absolute top-2 right-2 p-2 text-white hover:text-pink-300 transition-colors duration-300"
          >
            <Info size={24} />
          </button>
          <div 
            className="mb-4 h-[310px] overflow-y-auto pr-2"
            style={{
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '10px',
                margin: '5px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                borderRadius: '10px',
                border: '2px solid rgba(255, 255, 255, 0.1)',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
              },
              scrollbarWidth: 'thin',
              scrollbarColor: 'rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1)',
            }}
          >
            {activeTab === 'rondes' ? renderRondesSettings() : renderAdemhalingenSettings()}
          </div>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleFirstInteraction}
            className="w-full py-2 bg-gradient-to-r from-pink-500 to-purple-600 text-white rounded-full text-sm font-semibold hover:from-pink-600 hover:to-purple-700 transition-colors transform hover:scale-105 duration-200 shadow-lg"
          >
            {t('startExercise')}
          </motion.button>
        </motion.div>
      ) : (
        <div className="flex flex-col items-center w-full text-white p-4 space-y-3">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="relative mb-3"
            style={{ width: sizes.maxSize, height: sizes.maxSize }}
          >
            <BreathingCircle
              size={size}
              phase={phase}
              holdTimer={holdTimer}
              maxHoldTime={
                activeTab === 'rondes'
                  ? (phase === 'hold'
                    ? (progressiveHoldIncrease ? holdDuration + (roundCount - 1) * 30 : holdDuration)
                    : inhalationHoldDuration)
                  : (phase === 'inhaleHold' ? ademhalingenInhaleHold : ademhalingenExhaleHold)
              }
              sizes={sizes}
            />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl sm:text-5xl md:text-6xl font-bold">
              {(phase === 'inhaleHold' || phase === 'exhaleHold' || phase === 'hold') ? formatTime(holdTimer) :
                (phase === 'inhale' || phase === 'postHoldInhale' ? 'In' : language === 'nl' ? 'Uit' : 'Out')}
            </div>
          </motion.div>

          {!isFullScreen && (
            <>
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="text-xl sm:text-2xl font-bold"
              >
                {getInstructionText()}
              </motion.p>

              {activeTab === 'rondes' ? (
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className="flex justify-center items-center space-x-6"
                >
                  <div className="flex items-center">
                    <Moon className="mr-2 text-yellow-200" size={20} />
                    <span className="text-base sm:text-lg">
                      {t('breathing')} | {breathCount}/{activeTab === 'rondes' ? breathsPerRound : ademhalingenBreaths}
                    </span>
                  </div>
                  <div className="text-base sm:text-lg">
                    <span>{t('round')} | {roundCount}/{totalRounds}</span>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className="text-base sm:text-lg flex items-center"
                >
                  <Moon className="mr-2 text-yellow-200" size={20} />
                  <span>{t('breathing')} | {breathCount}/{ademhalingenBreaths}</span>
                </motion.div>
              )}
            </>
          )}

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="flex justify-center gap-2 sm:gap-4 w-full"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={togglePause}
              className="px-3 sm:px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-full flex items-center justify-center text-sm sm:text-base transition-all duration-200 flex-1 max-w-[140px]"
              aria-label={isPaused ? t('resume') : t('pause')}
            >
              {isPaused ? <Play size={20} /> : <Pause size={20} />}
              <span className="ml-2">{isPaused ? t('resume') : t('pause')}</span>
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={toggleFullScreen}
              className="px-3 sm:px-4 py-2 bg-gradient-to-r from-purple-500 to-purple-600 text-white rounded-full flex items-center justify-center text-sm sm:text-base transition-all duration-200 w-[50px] sm:w-[60px]"
              aria-label={isFullScreen ? t('exitFullScreen') : t('enterFullScreen')}
            >
              {isFullScreen ? <Minimize size={24} /> : <Maximize size={24} />}
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={restartExercise}
              className="px-3 sm:px-4 py-2 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-full flex items-center justify-center text-sm sm:text-base transition-all duration-200 flex-1 max-w-[140px]"
              aria-label={t('restart')}
            >
              <RefreshCw size={20} />
              <span className="ml-2">{t('restart')}</span>
            </motion.button>
          </motion.div>

          {!isFullScreen && (activeTab === 'ademhalingen' || (activeTab === 'rondes' && !variableBreathDuration)) && (
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="text-2xl sm:text-3xl font-bold"
            >
              {formatTime(remainingTime)}
            </motion.div>
          )}
        </div>
      )}
      <CookieConsent
        location="bottom"
        buttonText={language === 'nl' ? "Ik ga akkoord" : "I agree"}
        cookieName="breathingAppConsentCookie"
        style={{
          background: "rgba(43, 55, 59, 0.9)",
          backdropFilter: "blur(10px)",
          boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)",
        }}
        buttonStyle={{
          background: "linear-gradient(to right, #EC4899, #8B5CF6)",
          color: "white",
          fontSize: "14px",
          padding: "10px 20px",
          borderRadius: "9999px",
          fontWeight: "bold",
          transition: "opacity 0.2s",
        }}
        buttonClasses="hover:opacity-90"
        expires={150}
        overlayClasses="bg-indigo-900 bg-opacity-50"
      >
        <div className="text-white">
          <h3 className="text-lg font-bold mb-2">
            {language === 'nl' ? "Cookie-toestemming" : "Cookie Consent"}
          </h3>
          <p className="text-sm">
            {language === 'nl' ? (
              <>
                Deze website gebruikt cookies, waaronder die van Google Analytics, om de gebruikerservaring te verbeteren en het gebruik van de site te analyseren.
                <span className="text-xs block mt-1">
                  Door deze banner te sluiten of door te gaan met het gebruik van de site, stemt u in met ons gebruik van cookies.
                </span>
                <button
                  onClick={() => setCurrentPage('cookiebeleid')}
                  className="text-pink-300 hover:text-pink-200 underline mt-2 inline-block transition-colors duration-200"
                >
                  Meer informatie over ons cookiebeleid
                </button>
              </>
            ) : (
              <>
                This website uses cookies, including those from Google Analytics, to enhance the user experience and analyze site usage.{" "}
                <span className="text-xs block mt-1">
                  By closing this banner or continuing to use the site, you agree to our use of cookies.
                </span>
                <button
                  onClick={() => setCurrentPage('cookiebeleid')}
                  className="text-pink-300 hover:text-pink-200 underline mt-2 inline-block transition-colors duration-200"
                >
                  More information about our cookie policy
                </button>
              </>
            )}
          </p>
        </div>
      </CookieConsent>
      <AnimatePresence>
        {showFavoritesModal && renderFavoritesModal()}
      </AnimatePresence>
      {showNotification && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg">
          {t('settingsAdded')}
        </div>
      )}

      {/* Voeg deze knoppen toe naast de bestaande knoppen */}
      <div className="absolute top-4 right-4 flex space-x-2">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setSoundEnabled(prev => !prev)}
          className="p-2 bg-white bg-opacity-20 text-white rounded-full transition-colors duration-300 hover:bg-opacity-30"
          aria-label={soundEnabled ? t('disableSound') : t('enableSound')}
        >
          {soundEnabled ? <Volume2 size={24} /> : <VolumeX size={24} />}
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={changeTheme}
          className="p-2 bg-white bg-opacity-20 text-white rounded-full transition-colors duration-300 hover:bg-opacity-30"
          aria-label={t('changeTheme')}
        >
          <Palette size={24} />
        </motion.button>
      </div>
    </motion.div>
  );

  const getCurrentPattern = () => {
    if (!usePatterns) {
      return {
        inhale: inhaleDuration,
        exhale: exhaleDuration
      };
    }

    let breathsCompleted = 0;
    for (const pattern of breathPatterns) {
      if (breathCount <= breathsCompleted + pattern.breaths) {
        // Update de animatie duratie wanneer we een nieuw patroon beginnen
        if (breathCount === breathsCompleted + 1) {
          setAnimationDuration({
            inhale: pattern.inhale * 1000,
            exhale: pattern.exhale * 1000,
            hold: holdDuration * 1000,
            inhaleHold: inhalationHoldDuration * 1000,
            postHoldInhale: 2000,
            postHoldExhale: 2000
          });
        }
        return pattern;
      }
      breathsCompleted += pattern.breaths;
    }
    return breathPatterns[breathPatterns.length - 1];
  };

  if (phase === 'complete') {
    ReactGA.event({
      category: 'Exercise',
      action: 'Completed',
      label: activeTab
    });
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`flex items-center justify-center min-h-screen bg-gradient-to-br ${currentTheme.from} ${currentTheme.via} ${currentTheme.to} p-4`}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="text-center text-white bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl shadow-2xl p-8 border border-white border-opacity-20"
        >
          <Sun className="mx-auto mb-6 text-yellow-400" size={64} />
          <h1 className="text-3xl sm:text-4xl font-bold mb-4">{t('completed')}</h1>
          <p className="text-xl sm:text-2xl mb-8">{t('feelRefreshed')}</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={restartExercise}
            className="px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-600 text-white rounded-full text-lg sm:text-xl font-semibold hover:from-pink-600 hover:to-purple-700 transition-colors flex items-center justify-center mx-auto transform duration-200 shadow-lg"
          >
            <RefreshCw className="mr-3" size={24} />
            {t('startAgain')}
          </motion.button>
        </motion.div>
      </motion.div>
    );
  }

  const pageVariants = {
    initial: { opacity: 0 },
    in: { opacity: 1 },
    out: { opacity: 0 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.2
  };

  return (
    <div className={`min-h-screen bg-gradient-to-br ${currentTheme.from} ${currentTheme.via} ${currentTheme.to} overflow-hidden`}>
      <AnimatePresence mode="wait">
        {showIntro ? (
          <motion.div
            key="intro"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            className="absolute inset-0 z-10"
          >
            <IntroScreen onClose={handleCloseIntro} language={language} currentTheme={currentTheme} />
          </motion.div>
        ) : (
          <motion.div
            key="main"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            className="absolute inset-0 z-10"
          >
            {currentPage === 'main' ? (
              renderMainContent()
            ) : (
              <CookieBeleid language={language} onBack={() => setCurrentPage('main')} currentTheme={currentTheme} />
            )}
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showLoginModal && renderLoginModal()}
      </AnimatePresence>
      {showIOSInstallPrompt && (
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 100 }}
          transition={{ duration: 0.3 }}
          className="fixed bottom-0 left-0 right-0 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg text-white p-4 shadow-lg z-50"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Share className="h-6 w-6 mr-2" />
              <div>
                <p className="font-semibold">{t('addToHomeScreen')}</p>
                <p className="text-sm">{t('installInstructions')}</p>
              </div>
            </div>
            <button
              onClick={() => setShowIOSInstallPrompt(false)}
              className="text-pink-300 hover:text-pink-200 underline text-sm"
            >
              {t('gotIt')}
            </button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default BreathingApp;